@import "./vars.scss";

.header {
  // width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  outline: 1px solid #e4e5e7;
  background-color: white;
  padding: 11px 20px;
  & > img {
    height: 22px;
    padding: 10px;
  }
  .icon_wrapper {
    display: flex;
    padding: 5px 10px;
    border-radius: 50px;

    &:hover {
      cursor: pointer;
    }

    & > .icon {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.dropdown_box {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 60px;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1;
  padding: 0px 15px;
  width: 240px;

  & > div {
    padding: 0px !important;
  }

  .dropdown_item {
    display: flex;
    align-items: center;
    padding: 13px 0px;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    width: 100%;

    &:first-of-type {
      border-bottom: 1px solid #e4e5e7;
    }

    .user_info {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      max-width: 100%;

      color: #2a2f3c;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      letter-spacing: 0.25px;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      span:last-child {
        font-size: 14px;
        color: #777;
      }
    }

    &:has(.log_out) {
      cursor: pointer;
    }

    .log_out {
      color: $secondary;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      letter-spacing: 0.25px;
    }

    img {
      display: inline-block;
      width: 30px;
      margin-right: 10px;
      border-radius: 50%;
    }
  }
}
