@import "./vars.scss";

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.boundary-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    max-width: 100%;
    height: auto;
  }
  p {
    padding-top: 3%;
    margin: auto;
    text-align: center;
  }
}

.error-boundary-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  & > i {
    color: red;
    font-size: 36px;
  }
  & > h1 {
    text-align: center;
    width: 70%;

    & > a {
      color: #223e80;
    }
  }
}
