@import "./vars.scss";

.login-wrapper {
  @extend %wrapper;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/images/login_bg.svg");
  @extend %background_centered;

  & > .login-box {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.94);
    box-shadow: 0px 4px 44px 0px rgba(41, 125, 115, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;
    & > img {
      margin-bottom: 10px;
    }
    & > span {
      margin-bottom: 60px;
      color: $placeholder;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      letter-spacing: 0.25px;
    }

    & > i {
      font-size: 50px;
      color: #395dab;
    }

    & > button {
      display: flex;
      padding: 4px;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      background-color: #395dab;
      color: white;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      border: none;
      padding-right: 8px;

      & > span {
        width: 30px;
        height: 30px;
        background-color: white;
        color: #395dab;
        padding: 10px;
      }
    }
  }
}
