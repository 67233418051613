$primary: #14171f;
$secondary: #414758;
$label: #737a8c;
$placeholder: #9095a2;

%wrapper {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

%centered {
  align-items: center;
  justify-content: center;
}

%background_centered {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

%primary_button {
  min-width: 160px;
  align-self: flex-start;
  padding: 12px 14px;
  text-align: center;
  border: none;
  border-radius: 23px;
  background: var(--Teal-400, #23b082);
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.25px;
}

%input_box {
  padding: 6px 12px;
  color: $secondary;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.25px;

  border-radius: 6px;
  border: 1px solid #acb0b9;
  background-color: white;
  outline: none;
  &::placeholder {
    color: $placeholder;
  }
}
