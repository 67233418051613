@import "./vars.scss";

.dashboard-wrapper {
  @extend %wrapper;

  display: flex;
  align-items: center;
  padding: 40px 0px;
  padding-bottom: 50px;
  gap: 20px;
  @extend %background_centered;
  background-color: #f7f7f8;
  overflow-y: auto;
  &:has(form) {
    background-image: url("../assets/images/home_bg.svg");
  }
  & > .image_wrapper {
    width: 50%;
    padding: 0px 50px;
    display: flex;
  }

  & > form {
    width: 50%;
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 50px;

    & > .input_row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      & > .input_container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: calc(50% - 14px);
        &.full {
          width: 100%;
        }

        & > label {
          color: $primary;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
          letter-spacing: 0.25px;
        }
        & input {
          @extend %input_box;
        }

        & > span {
          color: $label;
          font-size: 12px;
          font-style: italic;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.4px;
          &.footer-text {
            color: $secondary;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            letter-spacing: 0.25px;
          }
        }

        & > button {
          @extend %primary_button;
        }

        & > .domain-group {
          display: flex;
          align-items: center;
          gap: 4px;
          & > label {
            color: #2a2f3c;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            letter-spacing: 0.25px;
          }
        }
      }
    }

    & > button {
      @extend %primary_button;
    }
  }

  .progress-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    & > .description {
      color: $secondary;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.25px;
    }

    & > .progress-container {
      width: 100%;
      padding: 20px 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-radius: 20px;
      border: 1px solid hsl(220, 6%, 90%);
      background: #fff;
      & > .progress-bar-container {
        width: 100%;
        min-height: 10px;
        border-radius: 7px;
        overflow: hidden;
        background: #e4e5e7;
        position: relative; /* relative here */

        & > .progress-bar-indicator {
          height: 100%;
          border-radius: 7px;
          /* this will do the magic */
          -webkit-mask: linear-gradient(#fff 0 0);
          mask: linear-gradient(#fff 0 0);
        }
        & > .progress-bar-indicator::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(270deg, #23b082 0%, #5284d9 100%);
        }
      }
      & > .label-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        & > span {
          color: #5a6072;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.15px;
        }
      }
    }

    & > .steps-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 42px;
      & > .step-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        & > .title {
          text-align: left;
          color: #333;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        & > .box {
          width: 100%;
          padding: 60px 20px;
          border-radius: 20px;
          border: 1px solid #e4e5e7;
          background: #fff;
        }
      }
    }
  }

  .title {
    color: #333;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.25px;
  }

  & > .footer {
    margin: 40px 0px;
    color: $placeholder;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.15px;
    & > a {
      color: #23b082;
    }
  }
}
